body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rbc-event-label{
  display:none;
}

.p-toast-message-content {
  padding: 1rem;
    border-width: 0 0 0 6px;
}

.p-toast-message-error {
  background: rgba(255, 231, 230, 0.7);
  border: solid #ff5757;
  border-width: 0 0 0 6px;
  color: #ff5757;
}

.p-toast-message-success {
  background: rgba(228, 248, 240, 0.7);
  border: solid #1ea97c;
  border-width: 0 0 0 6px;
  color: #1ea97c;
}

.p-toast-message-info {
  background: rgba(219, 234, 254, 0.7);
  border: solid #3b82f6;
  border-width: 0 0 0 6px;
  color: #3b82f6;
}

.p-toast-message-warn {
  background: rgba(255, 242, 226, 0.7);
  border: solid #cc8925;
  border-width: 0 0 0 6px;
  color: #cc8925;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  font-family: "Inter var", sans-serif;
  margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  font-family: "Inter var", sans-serif;
  margin: 0.5rem 0 0 0;
}

.p-component {
  /* font-family: "Inter var", sans-serif; */
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 16px;
  font-weight: normal;
}